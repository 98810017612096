(function (angular) {
    'use strict';
    angular.module('KisanSatApp')
        .factory('GenericMapHelper', GenericMapHelper);

    GenericMapHelper.$inject = ['leafletData'];

    function GenericMapHelper(leafletData) {
        var layers = {
            googleHybrid: {
                name: 'Google Hybrid', type: 'google', layerType: 'HYBRID', noWarp: true
            }, ESRI_satellite_layer: {
                name: 'ESRI Satellite',
                type: 'xyz',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                noWrap: true
            }, Esri_WorldGrayCanvas: {
                name: 'ESRI World Gray',
                type: 'xyz',
                url: 'http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
                maxZoom: 16,
                attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
                noWrap: true
            }, OSM: {
                name: 'Openstreetmap',
                type: 'xyz',
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                noWrap: true
            }
        };

        function __createFieldLayer(data, mapid) {
            var polygonLayer = createFieldPolygonLayer(data);
            leafletData.getMap(mapid).then(function (map) {
                map.addLayer(polygonLayer);
                map.fitBounds(polygonLayer.getBounds(), {padding: [100, 100]});
                polygonLayer.bringToFront();
            });
            return polygonLayer;

            function createFieldPolygonLayer(data) {
                var getStyle = {
                    'weight': 2,
                    'opacity': 1,
                    'stroke': 'black',
                    'dashArray': 10,
                    'fillOpacity': 0.1
                };
                return (L.geoJson(data, {
                    name: 'BookField',
                    layer: 'BookFieldLayer',
                    group: 'BookFarmField',
                    onEachFeature: onEachFeature,
                    style: getStyle
                }));
            }

            function onEachFeature(feature, layer, mapid) {
                leafletData.getMap(mapid).then(function (map) {
                    L.marker(layer.getBounds().getCenter(), {
                        icon: L.divIcon({
                            className: 'label',
                            html: '<span id=' + layer.feature.properties.field_slug + '>' + layer.feature.properties.field_name + '</span>',
                            iconSize: [100, 50],
                        })
                    }).addTo(map);
                });
            }
        }

        function __createPlotLayer(data, mapid) {
            var polygonLayer = createPlotPolygonLayer(data);
            leafletData.getMap(mapid).then(function (map) {
                map.addLayer(polygonLayer);
                // map.fitBounds(polygonLayer.getBounds(), {padding: [100, 100]});
                polygonLayer.bringToFront();
            });
            return polygonLayer;

            function createPlotPolygonLayer(data) {
                return (L.geoJson(data, {
                    name: 'BookPlot',
                    layer: 'BookPlotLayer',
                    group: 'BookPlot',
                    // onEachFeature: onEachFeature,
                    style: {
                        color: 'orange',
                        fillColor: 'orange',
                        weight: 4,
                        opacity: 1,
                        fillOpacity: 0.7
                    }
                }));
            }
        }

        function __findFeature(slugToMatchWith, geojsonfeatures, featureSlug) {
            return (L.geoJson(geojsonfeatures, {
                filter: function (feature, layer) {
                    return feature.properties[featureSlug] === slugToMatchWith;
                }
            }));
        }

        function __createMap(vm) {
            angular.extend(vm, {
                center: {
                    lat: 51.505,
                    lng: -0.09,
                    zoom: 4
                },
                layers: {
                    baselayers: {
                        googleHybrid: layers.googleHybrid,
                        ESRI: layers.ESRI_satellite_layer,
                        ESRIWC: layers.Esri_WorldGrayCanvas,
                        OSM: layers.OSM
                    },
                    tileLayerOptions: {
                        opacity: 0.9,
                        detectRetina: true,
                        reuseTiles: true
                    },
                    overlays: {
                        draw: {
                            name: 'draw',
                            type: 'markercluster',
                            visible: true,
                            layerParams: {
                                showOnSelector: false
                            }
                        }
                    },
                    controls: {
                        zoomControl: {zoomControl: false, position: 'bottomright'},
                        fullscreen: {position: 'topleft'},
                        scale: {scale: true, position: 'bottomright'},
                        draw: {
                            draw: {
                                marker: false,
                                polyline: false,
                                polygon: false,
                                rectangle: false,
                                circle: false
                            }
                        }
                    }
                }
            });
        }

        function __removeLayer(layer, mapid) {
            leafletData.getMap(mapid).then(function (map) {
                if (layer) {
                    map.removeLayer(layer);
                }
            });
        }

        return {
            createFieldLayer: __createFieldLayer,
            createPlotLayer: __createPlotLayer,
            createMap: __createMap,
            removeLayer: __removeLayer
        };
    }

})(angular);