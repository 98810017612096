angular.module('KisanSatApp')
    .controller('SatellitePublishedDatasetDeleteController',
        function SatellitePublishedDatasetDeleteController($scope, $rootScope, $filter, $khMessage, $mdDialog,
                                                           $http, $window, SatelliteServiceDeleteReasonResource,
                                                           SatellitePublishedDatasetResource, ErrorLoggingService,
                                                           subscription_dict) {
            var vm = $scope;

            var AppHelpers = appHelpers();

            //Initialisation and configuration
            $rootScope.page_id = '';
            $rootScope.sub_app_page_id = null;
            $rootScope.currentNavItem = '';
            AppHelpers.initObject();
            vm.delete_satellite_published_data_delete = function () {
                var delete_reason_slug = vm.satellite_published_data_deletion.delete_reason_slug;
                var object_slug = vm.satellite_published_data_deletion.published_dataset_slug;
                SatellitePublishedDatasetResource.delete({
                    publish_slug: object_slug,
                    delete_reason_slug: delete_reason_slug
                }, function () {
                    $khMessage.show($filter('translate')('deleted_successfully'), 5000);
                }, function (error) {
                    ErrorLoggingService.genericAPIError(error);
                });
                $mdDialog.hide();
            };

            function appHelpers() {
                return {
                    initObject: function () {
                        SatelliteServiceDeleteReasonResource.get(function (result_data) {
                            var delete_options = result_data.data;
                            vm.satellite_published_data_deletion = subscription_dict;
                            vm.satellite_published_data_deletion_form = AppHelpers.load_satellite_delete_service(delete_options);
                        });
                    },
                    load_satellite_delete_service: function (delete_options) {
                        return [
                            {
                                className: 'flex-100',
                                type: 'radio',
                                key: 'delete_reason_slug',
                                id: 'service_id',
                                templateOptions: {
                                    required: true,
                                    label: 'Select reason for deletion',
                                    placeholder: 'Select reason for deletion',
                                    valueProp: 'slug',
                                    labelProp: 'deletion_reason',
                                    options: delete_options
                                }
                            }
                        ];
                    },
                };
            }
        });


