angular.module("KhChart").directive('multiSeriesLineChart', MultiSeriesLineDirective);

function MultiSeriesLineDirective() {
    return {
        scope: {
            name: "=",
            seriesdata: "=",
            xaxisconfig: "=",
            yaxisconfig: "="
        },
        restrict: 'E',
        controller: MultiSeriesLineController,
        controllerAs: 'vm',
        templateUrl: '/static/assets/khapps/khchart/generic/GerenericChart.html'
    };
}

MultiSeriesLineController.$inject = ['$scope'];

function MultiSeriesLineController($scope) {
    if ($scope.seriesdata.length != 0) {
        $scope.chartConfiguration = {
            options: {
                title: {
                    text: $scope.name
                },
                chart: {
                    type: 'spline',
                    zoomType: 'xy'
                },
                xAxis: $scope.xaxisconfig,
                yAxis: $scope.yaxisconfig,
                tooltip: {
                    valueSuffix: '%'
                },
                legend: {
                    enabled: true,

                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColorSolid) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false,
                    y: -4
                }
            },
            series: $scope.seriesdata
        }
    } else {
        $scope.chartConfiguration = false;
    }
}