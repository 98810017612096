(function (angular) {
    'use strict';
    angular.module('KhChart')
        .controller('KhChartDirectiveController', ['$scope', function ($scope) {
            if ($scope.chartconfig) {
                $scope.chartConfiguration = $scope.chartconfig;
                if ($scope.tooltipfn) {
                    $scope.chartConfiguration['tooltip'] = {
                        formatter: $scope.tooltipfn
                    };
                }
            } else {
                $scope.chartConfiguration = false;
            }
        }])
        .directive('khChart', KhChartDirective);

    function KhChartDirective() {
        return {
            scope: {
                chartconfig: '=',
                tooltipfn: '='
            },
            restrict: 'E',
            controller: 'KhChartDirectiveController',
            controllerAs: 'vm',
            templateUrl: '/static/assets/khapps/khchart/generic/GerenericChart.html'
        };
    }
})(angular);

