(function (angular) {
    'use strict';
    angular.module('KisanSatApp')
        .controller('SatelliteServiceSubscriptionController', function($scope, $rootScope, $filter, $khMessage,
                                                                       $mdDialog, $http, $window,
                                                                       SatelliteServiceResource,
                                                                       SatelliteServiceSubscriptionResource,
                                                                       subscription_dict) {
        var vm = $scope;

        var AppHelpers = appHelpers();

        //Initialisation and configuration
        $rootScope.page_id = '';
        $rootScope.sub_app_page_id = null;
        $rootScope.currentNavItem = '';
        AppHelpers.initObject();
        vm.save_satellite_service = function () {
            var list_of_service = vm.satellite_service_subscription.list_of_service;
            var subscriptionDict=[];
            delete vm.satellite_service_subscription.list_of_service;
            vm.satellite_service_subscription.start_date = moment(vm.satellite_service_subscription.service_start_date).format('YYYY-MM-DD');
            list_of_service.forEach(function (service_type) {
                var newdict = {};
                angular.copy(vm.satellite_service_subscription, newdict);
                newdict['service'] = service_type;
                subscriptionDict.push(newdict);
            });
            subscriptionDict.forEach(function (subscription){
                SatelliteServiceSubscriptionResource.save(subscription, function (success_data) {
                    $khMessage.show('Submitted request for satellite subscription service successfully.', 10000);
                    $mdDialog.hide(success_data);
                }, function (error) {
                    $khMessage.show('Failed to submit subscription request. Please try again or get in touch. Error: ' + error);
                });
            });
            $mdDialog.hide();
        };

        function appHelpers() {
            return {
                initObject: function () {
                    SatelliteServiceResource.query(function (result_data) {
                        var satellite_service_data = result_data.data;
                        vm.satellite_service_subscription = subscription_dict;
                        vm.satellite_service_subscription_form = AppHelpers.load_satellite_service(satellite_service_data);
                    });
                },
                load_satellite_service: function(satellite_service_data){
                    return [
                        {
                            className: 'flex-100',
                            type: 'searchable_select',
                            key: 'list_of_service',
                            id: 'service_id',
                            templateOptions: {
                                required: true,
                                multiple: true,
                                label: 'Select service',
                                placeholder: 'Select service',
                                valueProp: 'slug',
                                labelProp: 'service_name',
                                options: satellite_service_data
                            }
                        },
                        {
                            className: 'flex-50',
                            type: 'datepicker',
                            key: 'service_start_date',
                            id: 'service_start_date_id',
                            defaultValue: new Date(),
                            templateOptions: {
                                format: 'DD MMMM YYYY',
                                label: 'Service Start Date',
                                required: true,
                                placeholder: 'dd/mm/yyyy',
                                //minDate: new Date(2016, 12, 31)
                            }
                        },
                        {
                            className: 'flex-50',
                            type: 'datepicker',
                            key: 'service_end_date',
                            id: 'service_end_date_id',
                            defaultValue: new Date(),
                            templateOptions: {
                                format: 'DD MMMM YYYY',
                                label: 'Service End Date',
                                required: true,
                                placeholder: 'dd/mm/yyyy',
                                disabled: true
                            },
                            controller: function ($scope) {
                                $scope.$watch('$parent.model.service_start_date', function handleChange(newVal, oldVal) {
                                    newVal = moment(newVal);
                                    if (newVal) {
                                        var endDate = newVal.add(12, 'months');
                                        $scope.model.service_end_date = endDate.toDate();
                                    }
                                });

                            }
                        },
                    ];
                },
            };
        }
    })

})(angular);

