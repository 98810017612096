(function (angular) {
    'use strict';
    angular.module('KisanSatApp')
        .factory('UserSettingsResource', UserSettingsResource);

    UserSettingsResource.$inject = ['$resource'];

    function UserSettingsResource($resource) {
        var url = 'api/v1.0/profiles/user-settings/';
        return $resource(
            url, {},
            {
                query: {
                    method: 'GET',
                    isArray: true
                },
                get: {
                    method: 'GET'
                },
                update: {
                    method: 'POST'
                },
            });
    }

})(angular);
