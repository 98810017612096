angular.module("KhChart").directive('scatterChart', ScatterDirective);

function ScatterDirective() {
    return {
        scope: {
            name: "=",
            seriesdata: "=",
            xaxisconfig: "=",
            yaxisconfig: "="
        },
        restrict: 'E',
        controller: ScatterController,
        controllerAs: 'vm',
        templateUrl: '/static/assets/khapps/khchart/generic/GerenericChart.html'
    };
}

ScatterController.$inject = ['$scope'];

function ScatterController($scope) {
    if ($scope.seriesdata.length != 0) {
        $scope.chartConfiguration = {
                title: {
                    text: $scope.name
                },
                chart: {
                    type: 'scatter',
                    zoomType: 'xy'
                },
                xAxis: $scope.xaxisconfig,
                yAxis: $scope.yaxisconfig,
                plotOptions: {
                    scatter: {
                        marker: {
                            radius: 25
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b> ' + this.point.myData + '</b><br> Ahead/Behind days: ' + this.point.x;
                    }
                },

                legend: {
                    enabled: false,

                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColorSolid) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false,
                    y: -4
                },
                series: $scope.seriesdata
        };
    }
    else {
        $scope.chartConfiguration = false;
    }
}